import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './@core/page-not-found/page-not-found.component';
import { AuthGuard } from './shared/services/auth.guard';

const routes: Routes = [
  {path:'', loadChildren: () => import('./home-page/home-page.module').then(m => m.HomePageModule)},
  {path:'products/qr', loadChildren: () => import('./product-detail/product-detail.module').then(m => m.ProductDetailModule)},
  {path:'products', loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule)},
  {path:'cart', loadChildren: () => import('./cart/cart.module').then(m => m.CartModule), canActivate:[AuthGuard]},
  {path:'auth', loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)},
  {path:'profile', loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule)},
  {path: '**', component: PageNotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export const environment = {
  production: true,

  firebaseConfig: {
    apiKey: "AIzaSyDGKgS6KE5yIKgaXtZfZwTPXJBZPtOFaFo",
    authDomain: "tokafarm88.firebaseapp.com",
    projectId: "tokafarm88",
    storageBucket: "tokafarm88.appspot.com",
    messagingSenderId: "773905974825",
    appId: "1:773905974825:web:b5b0c83c0ff337b6f0be38",
    measurementId: "G-D9CXBV8HN0"
  }
  
};



/**
 * apiKey: "AIzaSyDGKgS6KE5yIKgaXtZfZwTPXJBZPtOFaFo",
    authDomain: "tokafarm88.firebaseapp.com",
    projectId: "tokafarm88",
    storageBucket: "tokafarm88.appspot.com",
    messagingSenderId: "773905974825",
    appId: "1:773905974825:web:b5b0c83c0ff337b6f0be38",
    measurementId: "G-D9CXBV8HN0"
 * 
 */